import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en";
import uk from "./locales/uk";

i18n
    .use(LanguageDetector) // Detects the user's language
    .use(initReactI18next) // Connects i18next with React
    .init({
        resources: {
            en: {translation: en},
            uk: {translation: uk},
        },
        fallbackLng: "en", // Default to English if language is not found
        interpolation: {escapeValue: false}, // No need to escape values
        detection: {
            order: ["localStorage", "navigator"], // Detect language from localStorage or browser settings
            caches: ["localStorage"], // Store selected language in localStorage
        },
    });

export default i18n;
