import React, {useState} from 'react';
import { useTranslation } from "react-i18next";
import GuestLayout from "../../layouts/GuestLayout";
import GuestCard from "../../components/GuestCard";
import axiosInstance from "../../services/api/axiosInstance";
import urlCreator from "../../services/api/UrlCreator";
import {useLocation, useNavigate} from "react-router-dom";
import ROUTES from "../../routes";
import Input from "../../components/common/Input";
import TextButton from "../../components/common/TextButton";
import Button from "../../components/common/Button";
import styles from './styles.module.scss';
import CompanyObserver from "../../mobx/company/CompanyObserver";
import FetchService from "../../services/api/FetchService";
import { observer} from "mobx-react-lite";

const LoginPage = () => {
    const { t } = useTranslation();
    const [fields, setFields] = useState({
        name: '',
        password: ''
    });
    const [error, setError] = useState<string>('');

    const navigate = useNavigate();
    const location = useLocation();

    const handleSignup = () => navigate(ROUTES.REGISTER);

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const id = e.target.id;
        const value = e.target.value;

        setFields((prev) => ({
            ...prev,
            [id]: value,
        }));
    };

    const handleSubmit = async () => {
        const authData = btoa(unescape(encodeURIComponent(`${fields.name}:${fields.password}`)));

        const headers = {
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Authorization": `Basic ${authData}`
        };

        try {
            const res= await axiosInstance.post(urlCreator.signIn(), {}, {headers});
            const token = res.headers['x-jwt-token'];
            axiosInstance.defaults.headers.common['Authorization'] = token;
            await localStorage.setItem('token', token);

            const data = await FetchService.getCompanies();
            const firstCompanyInList = data?.data?.companies[0].id;

            await localStorage.setItem('company', String(firstCompanyInList));
            await CompanyObserver.setCompanyId(Number(firstCompanyInList));

            if(location.state) {
                navigate(location.state, {state: ROUTES.LOGIN});
            } else {
                navigate(ROUTES.HOME)
            }

        } catch (e) {
            setError(t('loginError') ?? 'Incorrect e-mail');
        }
    };

    return (
        <GuestLayout>
            <GuestCard>
                <div className={styles.login__container}>
                    <Input
                        type={'email'}
                        id={'name'}
                        onChange={handleChange}
                        value={fields.name}
                        placeholder={t('loginEmail') ?? 'E-mail or phone'}
                        errorText={error}
                    />
                    <Input
                        type={'password'}
                        id={'password'}
                        onChange={handleChange}
                        value={fields.password}
                        placeholder={t('loginPassword') ?? 'Password'}
                    />
                    <div className={styles.login__links}>
                        <TextButton
                            variant={'link'}
                            text={t('loginForgotPassword', { defaultValue: 'Forgot password' }) ?? 'Forgot password'}
                            to={ROUTES.RESET_PASSWORD_EMAIL}
                        />
                        <TextButton
                            onClick={handleSignup}
                            text={t('loginCreateAccount') ?? 'Create account'}
                        />
                    </div>
                    <Button
                        text={t('loginLoginIn') ?? 'Log in'}
                        onClick={handleSubmit}
                    />
                </div>
            </GuestCard>
        </GuestLayout>
    );
};

export default observer(LoginPage);
