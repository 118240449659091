import React, {useEffect, useRef, useState} from 'react';
import BaseLayout from "../../layouts/BaseLayout";
import axiosInstance from "../../services/api/axiosInstance";
import UrlCreator from "../../services/api/UrlCreator";
import TableCalls from "./components/TableCalls";
import {EnumCallStatus, ICallResource, ICallServer} from "../../resources/ICall.resource";
import DateFastFilter, {EnumDateFastFilterType} from "./components/DateFastFilter";
import DateService from "../../services/DateService";
import CustomDatePicker, {TDatePickerItem} from "../../components/CustomDatePicker";
import InputSearch from "./components/InputSearch";
import FilterTag from "./components/FilterTag";
import ClearAllTagsBtn from "./components/ClearAllTagsBtn";
import {useSearchParams} from "react-router-dom";
import CustomPagination from "./components/Pagination";
import PerPageDropDown from "./components/PerPageDropDown";
import {AxiosResponse} from "axios";
import SelectAdditional from "./components/SelectAdditionalFilters";
import {observer} from "mobx-react-lite";
import CompanyObserver from "../../mobx/company/CompanyObserver";
import dayjs from "dayjs";
import StatusNameColorFactory from "../../constants/StatusNameColorFactory";
import {EnumSortDirection, EnumSortType} from "./components/HeadFilter";
import Loader from "../../components/Loader";
import ModalFilterOperator, {IFilterSelect} from "./components/ModalFilterOperator";
import FetchService from "../../services/api/FetchService";
import AdditionalMenu from "../AdminGridPage/components/AdditionalMenu";
import CheckedMenu from "./components/CheckedMenu";
import {factoryPatchStatus} from "./components/ModalChangeStatus";
import LoadCallsFile from "./components/LoadCallsFile";
import ModalProblemResultRange from "../../components/ModalProblemResultRange";
import ReloadBtn from "./components/ReloadBtn";
import {toast} from "react-toastify";
import {SuccessToastSettings} from "../../services/toast/toastSettings";
import styles from './styles.module.scss';

export interface IDateFilter  {
    type: EnumDateFastFilterType;
}

const initialDateFilters = [
    {
        type: EnumDateFastFilterType.LAST_30_DAYS,
        isActive: true
    },
    {
        type: EnumDateFastFilterType.TODAY,
        isActive: false
    },
    {
        type: EnumDateFastFilterType.YESTERDAY,
        isActive:false
    },
    {
        type: EnumDateFastFilterType.CURRENT_WEEK,
        isActive:false
    },
    {
        type: EnumDateFastFilterType.CURRENT_MONTH,
        isActive:false
    },
    {
        type: EnumDateFastFilterType.CUSTOM_RANGE,
        isActive:false
    }
];

const HomePage = () => {
    const [allCalls, setAllCalls] = useState<ICallResource[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOpenProblemResultModal, setIsOpenProblemResultModal] = useState<boolean>(false);

    const tableRef= useRef<HTMLDivElement>(null);

    const handleOpenFilterModal = () => setIsOpen(true);
    const handleCloseFilterModal = () => setIsOpen(false);
    const handleOpenProblemResultModal = () => setIsOpenProblemResultModal(true);
    const handleCloseProblemResultModal = () => setIsOpenProblemResultModal(false);

    const handleSetSearchParams = (perPage: number) => setSearchParams((prev) => {
        prev.set('perPage', String(perPage))
        prev.set('page', '1')
        return prev;
    });

    const handleSetPage = (page: number) => setSearchParams((prev) => {
        prev.set('page', String(page))
        return prev;
    });

    const handleSetProblemResultFilter = (start: string, end: string, clean?:boolean) => {
        setSearchParams((prev) => {
            if(clean) {
                prev.delete('problemResultRange');
                return prev;
            }
            prev.set('problemResultRange', `${start}-${end}`);
            return prev;
        });
    };

    const handleSetOperators = (filter: IFilterSelect) => {
        setSearchParams((prev) => {
            const operator = prev.get('operator');

            if(operator){
                const operators = JSON.parse(operator) as IFilterSelect[];

                if(operators.find((item) => item.value === filter.value)){
                    const removeSelectedOperator = operators.filter((item) => item.value !== filter.value);

                    if(removeSelectedOperator.length === 0){
                        prev.delete('operator')
                        return prev;
                    }

                    prev.set('operator', JSON.stringify(removeSelectedOperator))
                    return prev;
                }

                operators.push(filter);
                prev.set('operator', JSON.stringify(operators))
                return prev;
            }

            prev.set('operator', JSON.stringify([filter]))
            return prev;
        })
    };

    const handleClearOperators = () => {
        setSearchParams((prev) => {
            prev.delete('operator')
            return prev;
        })
    };

    const handleSetStatus = (status: EnumCallStatus) => setSearchParams((prev) => {
        const statuses = prev.get('status');
        if(statuses){
            const arrStatuses = JSON.parse(statuses) as EnumCallStatus[];

            if(arrStatuses.includes(status)) {
                const filteredStatuses = arrStatuses.filter((item) => item !== status);
                const parsedFilteredStatuses = JSON.stringify(filteredStatuses);

                if(filteredStatuses.length === 0) {
                    prev.delete('status');
                    return prev;
                }

                prev.set('status', parsedFilteredStatuses);
                return prev;
            }

            prev.set('status', JSON.stringify([...arrStatuses, status]))
            return prev;
        } else {
            prev.set('status', JSON.stringify([status]))
            return prev;
        }
    });

    const handleSelectFastDateFilter = (start:string, end:string, type:EnumDateFastFilterType) => {

        setSearchParams((prev) => {
            prev.delete('phoneNumber');
            prev.set('startDate', start);
            prev.set('endDate', end);
            prev.set('dateFilterType', type);
            prev.set('page', '1');
            return prev;
        })
    }

    const handleSelectDate = (date:[TDatePickerItem, TDatePickerItem]) => {

        setSearchParams((prev) => {
            prev.delete('phoneNumber');
            if(!date[0] && !date[1]){
                prev.set('dateFilterType', EnumDateFastFilterType.TODAY);
                prev.set('startDate', DateService.formatUI(String(new Date()), 'YYYY-MM-DD'));
                prev.set('endDate', DateService.formatUI(String(new Date()), 'YYYY-MM-DD'));
                return prev;
            }

            prev.set('startDate', DateService.formatUI(String(date[0]), 'YYYY-MM-DD'));
            prev.set('endDate', date[1] ? DateService.formatUI(String(date[1]), 'YYYY-MM-DD') : 'null');
            prev.set('dateFilterType', EnumDateFastFilterType.CUSTOM_RANGE);
            return prev;
        });
    }

    const getSearchParams = (key: string) => searchParams.get(key);
    const getSearchParamsCustomRangeDate = (key: string) => {
        if(searchParams.get('dateFilterType') !== EnumDateFastFilterType.CUSTOM_RANGE) return null;
        const value = searchParams.get(key);
        if(!value) return null;
        if('null' === value) return null;
        return new Date(value);
    }

    const handlePhoneFilter = (phoneNumber: string) => {
        if(getSearchParams('phoneNumber')){
            setSearchParams((prev) => {
                prev.delete('phoneNumber');
                prev.delete('search');
                return prev;
            })
        } else {
            setSearchParams((prev) => {
                prev.set('phoneNumber', phoneNumber);
                prev.delete('search');
                return prev;
            })
        }
    }

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchParams((prev) => {

            prev.delete('phoneNumber');
            prev.delete('problemsResult');
            prev.delete('topProblems');
            prev.set('search', value);
            if(!value) prev.delete('search');
            return prev;
        });
    };

    const handleSelectSort =(value: string) => {
        setSearchParams((prev) => {
            if(value === '_') {
                prev.delete('sort');
                prev.delete('topProblems')
                return prev;
            }

            prev.set('sort', value);
            prev.delete('topProblems')
            return prev;
        });
    };

    const handleProblemsResult = (problemsResult: string) => {
        setSearchParams((prev) => {
            const currentProblemResult = prev.get('problemsResult');
            if(currentProblemResult === problemsResult){
                prev.delete('problemsResult');
                prev.delete('phoneNumber');
                prev.delete('topProblems');
                prev.delete('search');
                return prev;
            }
            prev.set('problemsResult', problemsResult);
            prev.delete('phoneNumber');
            prev.delete('topProblems');
            prev.delete('search');
            return prev;
        })
    };

    const handleDefaultAnalyzed = () => {
        setSearchParams((prev) => {
            const isAnalyzed = prev.get('isAnalyzed');
            if(!isAnalyzed) {
                prev.set('isAnalyzed', '1');
                prev.delete('isNotAnalyzed')
            } else {
                prev.delete('isAnalyzed');
                prev.delete('isNotAnalyzed')
            }
            return prev;
        })
    };
    const handleNotAnalyzed = () => {
        setSearchParams((prev) => {
            const isNotAnalyzed = prev.get('isNotAnalyzed');
            if(!isNotAnalyzed) {
                prev.set('isNotAnalyzed', '1');
                prev.delete('isAnalyzed')
            } else {
                prev.delete('isNotAnalyzed');
                prev.delete('isAnalyzed')
            }
            return prev;
        })
    };

    const handleTopProblems = (topProblems: string) => {
        setSearchParams((prev) => {
            const currentTopProblems = prev.get('topProblems');
            if(currentTopProblems === topProblems) {
                prev.delete('topProblems');
                prev.delete('sort');
                return prev;
            }

            prev.delete('phoneNumber');
            prev.delete('search');
            prev.delete('problemsResult');
            prev.set('sort', `${EnumSortType.problemResult}_${EnumSortDirection.desc}`);
            prev.set('topProblems', topProblems);
            return prev;
        })
    };

    const parseOperators = () => {
        const operatorFilter = getSearchParams('operator');
        if(!operatorFilter) return '';
        const operators = JSON.parse(operatorFilter) as IFilterSelect[];
        const operatorIds = operators.map((unit) => Number(unit.value));

        return operatorIds.length ? `employee.id in [${operatorIds}] and` : '';
    }

    const paramsFactory = () => {
        const startDate = DateService.transformPicker(getSearchParams('startDate'));
        const endDate = getSearchParams('endDate');


        if(!startDate ) return;

        const perPage = getSearchParams('perPage');
        const page = getSearchParams('page');
        const phoneNumber = getSearchParams('phoneNumber');
        const problemsResult= getSearchParams('problemsResult');
        const topProblems= getSearchParams('topProblems');
        const status = getSearchParams('status');
        const statusFilter =  status ? `state in [${JSON.parse(status).map((item:EnumCallStatus, index:number) => `enum[PhoneCallState.${item}]`)}]` : '';
        const problemResultRange = getSearchParams('problemResultRange');
        const problemResultRangeSearchParam = problemResultRange ? `problemResult ge ${problemResultRange.split('-')[0]} and problemResult lt ${problemResultRange.split('-')[1]} and` : ''

        const generateStaticParams = () => {
            return statusFilter ?
                `${statusFilter} and callTime ge 'T${startDate}' and callTime lt 'T${endDateMiddleware()}'`
                :
                `callTime ge 'T${startDate}' and callTime lt 'T${endDateMiddleware()}'`
        };

        const searchParams = () => {
            const value = getSearchParams('search');
            if(!value) return null;

            if(value.match(`^[0-9]*$`)) {
                return `phoneNumber cp '*${value}*'`;
            } else {
                return `employee.user.fullName lp '*${value.toLowerCase()}*'`
            }
        }

        const endDateMiddleware = () => {
            if(getSearchParams('startDate') === endDate) {
                return DateService.transformPicker(dayjs(startDate).add(2, "day").format('YYYY-MM-DD'));
            }
            return !endDate || endDate === 'null' ? DateService.transformPicker(dayjs(startDate).add(1, "days").format('YYYY-MM-DD')) : DateService.transformPicker(endDate);
        }

        const getSortQuery = () => {
            const searchQuery = getSearchParams('sort');
            if(!searchQuery) return 'callTime desc';

            return `${searchQuery.split('_')[0]} ${searchQuery.split('_')[1]}`
        };

        const getIsAnalyzed = () => {
            const isAnalyzed = getSearchParams('isAnalyzed');
            const isNotAnalyzed = getSearchParams('isNotAnalyzed');
            if(isNotAnalyzed){
                return `problemResult Is null and`
            }

            if(!isAnalyzed) return '';


            return isAnalyzed === '1' ? `problemResult Not_Is null and` : '';
        }

        const params = new URLSearchParams();

        switch (true) {
            case Boolean(phoneNumber):
                params.set('pageSize', String(perPage));
                params.set('pageToken', String(Number(page) - 1));
                params.set('searchRequest', '');
                params.set('orderByRequest', getSortQuery());
                params.set('phoneCallUuid', String(phoneNumber?.split('_')[1]));
                return params;
            case Boolean(topProblems && status):
                params.set('pageSize', String(topProblems));
                params.set('searchRequest', `${getIsAnalyzed()} ${parseOperators()} ${statusFilter} ${problemResultRangeSearchParam} and callTime ge 'T${startDate}' and callTime lt 'T${endDateMiddleware()}'`);
                params.set('orderByRequest', getSortQuery());
                return params;
            case Boolean(topProblems) && Boolean(phoneNumber):
                params.set('pageSize', String(perPage));
                params.set('pageToken', String(Number(page) - 1));
                params.set('searchRequest', '');
                params.set('orderByRequest', getSortQuery());
                params.set('phoneCallUuid', String(phoneNumber?.split('_')[1]));
                return params;
            case Boolean(topProblems):
                params.set('pageSize', String(topProblems));
                params.set('searchRequest', `${getIsAnalyzed()} ${parseOperators()} problemResult gt 0 and callTime ge 'T${startDate}' and callTime lt 'T${endDateMiddleware()}'`);
                params.set('orderByRequest', getSortQuery());
                return params;
            case Boolean(problemsResult) && Boolean(phoneNumber):
                params.set('pageSize', String(perPage));
                params.set('pageToken', String(Number(page) - 1));
                params.set('searchRequest', '');
                params.set('orderByRequest', getSortQuery());
                params.set('phoneCallUuid', String(phoneNumber?.split('_')[1]));
                return params;
            case Boolean(problemsResult):
                params.set('pageSize', String(perPage));
                params.set('pageToken', String(Number(page) - 1));
                params.set('searchRequest', parseOperators() ? `${getIsAnalyzed()} problemResult ge ${problemsResult} and callTime ge 'T${startDate}' and callTime lt 'T${endDateMiddleware()}' and ${parseOperators()}` : `${getIsAnalyzed()} problemResult ge ${problemsResult} and ${generateStaticParams()}`);
                params.set('orderByRequest', getSortQuery());
                return params;
            case Boolean(searchParams()):
                params.set('pageSize', String(perPage));
                params.set('pageToken', String(Number(page) - 1));
                params.set('searchRequest', `${getIsAnalyzed()} ${searchParams()} ${problemResultRangeSearchParam} and ${generateStaticParams()}`);
                params.set('orderByRequest', getSortQuery());
                return params;
            case Boolean(phoneNumber) && Boolean(status):
                params.set('pageSize', String(perPage));
                params.set('pageToken', String(Number(page) - 1));
                params.set('searchRequest', `${getIsAnalyzed()} ${parseOperators()} ${problemResultRangeSearchParam} state eq enum[PhoneCallState.${status}]`);
                params.set('orderByRequest', getSortQuery());
                params.set('phoneCallUuid', String(phoneNumber?.split('_')[1]));
                return params;
            case Boolean(status):
                params.set('pageSize', String(perPage));
                params.set('pageToken', String(Number(page) - 1));
                params.set('searchRequest', parseOperators() ? `${getIsAnalyzed()} ${problemResultRangeSearchParam} callTime ge 'T${startDate}' and callTime lt 'T${endDateMiddleware()}' and ${parseOperators()}` : `${getIsAnalyzed()} ${problemResultRangeSearchParam} callTime ge 'T${startDate}' and callTime lt 'T${endDateMiddleware()}' and ${statusFilter}`);
                params.set('orderByRequest', getSortQuery());
                return params;
            default:
                params.set('pageSize', String(perPage));
                params.set('pageToken', String(Number(page) - 1));
                params.set('searchRequest', `${getIsAnalyzed()} ${parseOperators()} ${problemResultRangeSearchParam} callTime ge 'T${startDate}' and callTime lt 'T${endDateMiddleware()}'`);
                params.set('orderByRequest', getSortQuery());
                return params;
        }
    };

    const getAllCalls = () => {
        if(!CompanyObserver.companyId) return;
        setIsLoading(true);
        axiosInstance.get(UrlCreator.getAllCalls(CompanyObserver.companyId),{
            params: paramsFactory()
        }).then((res) => {
            setAllCalls(res.data.calls.map((item:ICallServer):ICallResource => ({
                ...item,
                isSelected:false
            })))
            setTotal(res.data.total)
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const getCallsFile = () => {
        if(!CompanyObserver.companyId) return;
        setIsLoading(true);

        const params = paramsFactory();
        if(!params) return;
        // params.delete('pageSize');
        // params.delete('pageToken');
        // if(!params.get('orderByRequest')) {
        //     params.delete('orderByRequest')
        // }

        axiosInstance.get(UrlCreator.getAllCallsFile(CompanyObserver.companyId),{
            params: params,
            headers: {
                accept: 'text/csv',
            }
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            const fileName = `downloaded Report ${dayjs(new Date()).format("DD MMM YY")}.csv`;
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            link.remove()
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const handleEditNotes = async (callId:string | number, notes:string) => {
        const companyId = CompanyObserver.getCompanyId();
        if(!companyId) return;

        try {
            const data = await FetchService.patchCallNotes(companyId, String(callId), notes);
            setAllCalls((prev) => {
                return prev.map((item) => {
                    return item.uuid === callId ? {...item, notes: notes} : item;
                })
            })
        } finally {

        }
    }

    const handlePatchStatus = (callId:string, status: string) => {
        if(!CompanyObserver.companyId) return;
        setIsLoading(true);
        axiosInstance.patch(UrlCreator.patchCallStatus(CompanyObserver.companyId, callId, status)).then((res:AxiosResponse<ICallResource>) => {
            setAllCalls((prev:ICallResource[]) => {
                return prev.map((item) => {
                   return item.uuid === callId ? res.data : item;
                });
            })
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const handlePatchStatusBatch = async (status: string) => {
        const batchCalls = allCalls.filter((unit) => unit.isSelected).map((item) => item.uuid);
        const factoryStatus = factoryPatchStatus(status as EnumCallStatus);

        setIsLoading(true);

        try {
            setIsLoading(true);
            await axiosInstance.patch(UrlCreator.patchCallBatchStatus(CompanyObserver.getCompanyId(), batchCalls, factoryStatus));
            setAllCalls((prev) => {
               return prev.map((item) => {
                   return batchCalls.includes(item.uuid) ? {...item, state: status as EnumCallStatus} : item
               })
            });
        } finally {
            setIsLoading(false);
        }

    }

    const getTags = () => {
        const tags = [];
        const phoneNumber = searchParams.get('phoneNumber');
        const search = searchParams.get('search');
        const problemsResult= getSearchParams('problemsResult');
        const topProblems= getSearchParams('topProblems');
        const status= getSearchParams('status');
        const operator= getSearchParams('operator');
        const problemResultRange= getSearchParams('problemResultRange');

        if(phoneNumber) {
            tags.push({
                title: phoneNumber.split('_')[0],
                type: 'phoneNumber'
            })
        }
        if(problemResultRange) {
            tags.push({
                title: `Оцінка ${problemResultRange}`,
                type: 'problemResultRange'
            })
        }
        if(operator) {
            const operatorList= JSON.parse(operator) as IFilterSelect[];
            const operatorTags = operatorList.map((item) => ({
                title: item.label,
                type: `operator_${item.value}`
            }));
            tags.push(...operatorTags);
        }
        if(problemsResult) {
            tags.push({
                title: `${problemsResult}+`,
                type: 'problemsResult'
            })
        }
        if(topProblems) {
            tags.push({
                title: `топ ${topProblems}`,
                type: 'topProblems'
            })
        }
        if(search) {
            tags.push({
                title: search,
                type: 'search'
            })
        }
        if(status) {
            const arrStatus = JSON.parse(status) as EnumCallStatus[];
            const statuses = arrStatus.map((item) => {
                return {
                    title: StatusNameColorFactory(item as EnumCallStatus).name,
                    type: `status-${item}`
                }
            })

            tags.push(...statuses)
        }
        return tags;
    };

    const handleClearTag = (type:string) => {

        setSearchParams((prev) => {
            if(type.split('_')[0] === 'operator') {
                const operators = prev.get('operator');
                if(!operators) return prev;

                const parsedOperators = JSON.parse(operators) as IFilterSelect[];
                const filteredOperators = parsedOperators.filter((item:IFilterSelect) => item.value !== type.split('_')[1]);
                if(filteredOperators.length === 0){
                    prev.delete('operator');
                    return prev;
                }
                prev.set('operator', JSON.stringify(filteredOperators));
                return prev;
            }
            if(type.split('-')[0] === 'status') {
                const statuses = prev.get('status');
                if(!statuses) return prev;

                const parsedStatuses = JSON.parse(statuses) as EnumCallStatus[];
                const filteredStatuses = parsedStatuses.filter((item:EnumCallStatus) => item !== type.split('-')[1]);

                if(filteredStatuses.length === 0){
                    prev.delete('status');
                    return prev;
                }
                prev.set('status', JSON.stringify(filteredStatuses));
                return prev;
            }

            prev.delete(type);
            return prev;
        })
    }

    const handleRefresh = async () => {
         try {
             setIsLoading(true);
             const {data} = await FetchService.postRefreshEmployees();
             toast.success(`Оновлено дані по ${data.length} користувачам`, SuccessToastSettings);
         } finally {
             setIsLoading(false);
         }
    };

    const handleClearAllTags = () => {
        setSearchParams((prev) => {
            prev.delete('phoneNumber');
            prev.delete('search');
            prev.delete('problemsResult');
            prev.delete('topProblems');
            prev.delete('status');
            prev.delete('operator');
            prev.delete('problemResultRange');
            return prev;
        })
    }

    useEffect(() => {
        if(!getSearchParams('page') || !getSearchParams('perPage') || !getSearchParams('dateFilterType') || !getSearchParams('isAnalyzed')) {
            setSearchParams((prev) => {
                prev.set('page', '1');
                prev.set('isAnalyzed', '1');
                prev.set('perPage', '50');
                prev.set('dateFilterType', EnumDateFastFilterType.LAST_30_DAYS);
                prev.set('startDate', DateService.getLast30Days().start);
                prev.set('endDate', DateService.getLast30Days().end);
                return prev;
            });
        }
    }, []);

    useEffect(() => {
        if(!getSearchParams('page') || !getSearchParams('perPage') || !getSearchParams('dateFilterType')) {
            setSearchParams((prev) => {
                prev.set('page', '1');
                prev.set('perPage', '50');
                prev.set('dateFilterType', EnumDateFastFilterType.LAST_30_DAYS);
                prev.set('startDate', DateService.getLast30Days().start);
                prev.set('endDate', DateService.getLast30Days().end);
                return prev;
            });
        }
    }, [searchParams]);

    useEffect(() => {
        getAllCalls();
    },[searchParams, CompanyObserver.companyId]);

    const totalCountFactory = ():number => {
        const topProblems = getSearchParams('topProblems');

        if(!topProblems) return total;
        if(total === 0) return 0;

        return Number(topProblems) > total ? total : Number(topProblems);
    }

    const handleCheckCall = (e:React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        const uuid = e.target.id;
        if(uuid ==='ALL') {
            setAllCalls((prev) => {
                if(prev.every((item) => item.isSelected)){
                    return prev.map((item) => ({
                        ...item,
                        isSelected: false
                    }))
                }

                return prev.map((item) => ({
                    ...item,
                    isSelected: true
                }))
            })
        }

        setAllCalls((prev) => {
            return prev.map((item) => {
                return item.uuid === uuid ? {...item, isSelected: !item.isSelected} : item
            })
        })
    }

    return (
        <>
            {isLoading ? <Loader/> : null}
            {isOpen ? (
                <ModalFilterOperator
                    handleClose={handleCloseFilterModal}
                    handleSetOperator={handleSetOperators}
                    selectedOperatorsJSON={searchParams.get('operator')}
                    handleClearOperators={handleClearOperators}
                />
            ) : null}
            {isOpenProblemResultModal ? (
                <ModalProblemResultRange
                    handleSetProblemResult={handleSetProblemResultFilter}
                    selectedProblemResultJSON={searchParams.get('problemResultRange')}
                    handleClose={handleCloseProblemResultModal}
                />
            ) : null}
            <BaseLayout>
                <div className={styles.automatic__header}>
                    <div className={styles.automatic__dates}>
                        {initialDateFilters.map((item:IDateFilter) => {
                            if(item.type === EnumDateFastFilterType.CUSTOM_RANGE) return null;
                            return (
                                <DateFastFilter
                                    key={item.type}
                                    type={item.type}
                                    isActive={String(item.type) === getSearchParams('dateFilterType')}
                                    handleSetDate={handleSelectFastDateFilter}
                                />
                            )
                        })}
                        <SelectAdditional
                            handleSetStatus={handleSetStatus}
                            status={JSON.parse(getSearchParams('status') as string) as EnumCallStatus[]}
                            handleProblemsResult={handleProblemsResult}
                            handleTopProblems={handleTopProblems}
                            problemsResult={getSearchParams('problemsResult')}
                            topProblems={getSearchParams('topProblems')}
                            handleDefaultAnalyzed={handleDefaultAnalyzed}
                            isAnalyzed={getSearchParams('isAnalyzed')}
                            isNotAnalyzed={getSearchParams('isNotAnalyzed')}
                            handleNotAnalyzed={handleNotAnalyzed}
                        />
                        <CustomDatePicker
                            startDate={getSearchParamsCustomRangeDate('startDate')}
                            endDate={getSearchParamsCustomRangeDate('endDate')}
                            handleSelectDate={handleSelectDate}
                        />
                        <div className={styles.automatic__total}>
                            Загальна кількість: {totalCountFactory()}
                        </div>
                    </div>
                    <div className={styles.automatic__header_left}>
                        <CheckedMenu
                            handlePatchStatusBatch={handlePatchStatusBatch}
                            amount={allCalls.filter((item) => item.isSelected).length}
                        />
                        <InputSearch
                            value={getSearchParams('search') ? String(getSearchParams('search')) : ''}
                            onChange={handleChange}
                        />
                        <LoadCallsFile onClick={getCallsFile} isLoading={isLoading}/>
                        <ReloadBtn onClick={handleRefresh}/>
                        <AdditionalMenu />
                    </div>
                </div>
                {getTags().length ? (
                    <div className={styles.automatic__tags}>
                        {getTags().map((item) => (
                            <FilterTag
                                key={item.title}
                                type={item.type}
                                title={item.title}
                                handleDelete={handleClearTag}
                            />
                        ))}
                        <ClearAllTagsBtn onClick={handleClearAllTags}/>
                    </div>
                ) : null}
                <TableCalls
                    ref={tableRef}
                    handleCheckCall={handleCheckCall}
                    filter={getSearchParams('operator') as string}
                    selectedSort={getSearchParams('sort') as EnumSortType}
                    handleSelectSort={handleSelectSort}
                    handlePhoneFilter={handlePhoneFilter}
                    data={allCalls}
                    handlePatchStatus={handlePatchStatus}
                    handleEditNotes={handleEditNotes}
                    handleOpenFilterModal={handleOpenFilterModal}
                    handleOpenProblemResultModal={handleOpenProblemResultModal}
                />
                <div className={styles.automatic__footer}>
                    <div/>
                    <CustomPagination
                        total={getSearchParams('topProblems') ? Number(getSearchParams('topProblems')) : total}
                        perPage={Number(getSearchParams('perPage'))}
                        page={Number(getSearchParams('page'))}
                        setPage={handleSetPage}
                    />
                    {!getSearchParams('topProblems') ? (
                        <PerPageDropDown
                            setPerPage={handleSetSearchParams}
                            perPage={Number(getSearchParams('perPage'))}
                        />
                    ) : null}
                </div>
            </BaseLayout>
        </>
    );
};

export default observer(HomePage);
